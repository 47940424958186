import { Title, Container, Main } from '../../components'
import styles from './styles.module.css'
import MetaTags from 'react-meta-tags'

const About = ({ updateOrders, orders }) => {

  return <Main>
    <MetaTags>
      <title>О проекте</title>
      <meta name="description" content="Фудграм - О проекте" />
      <meta property="og:title" content="О проекте" />
    </MetaTags>

    <Container>
      <h1 className={styles.title}>Привет!</h1>
      <div className={styles.content}>
        <div>
          <h2 className={styles.subtitle}>Что это за сайт?</h2>
          <div className={styles.text}>
            <p className={styles.textItem}>
            Цель этого сайта — предоставить пользователям удобную платформу для создания, хранения и обмена рецептами. Вы можете легко сохранять любимые блюда, загружать списки ингредиентов для покупок и следить за кулинарными предпочтениями друзей. Платформа также позволяет добавлять рецепты в избранное, чтобы они всегда были под рукой.
            </p>
            <p className={styles.textItem}>
            Для доступа ко всем функциям сайта требуется регистрация. Вход осуществляется без проверки адреса электронной почты, что упрощает процесс регистрации.</p>
            <p className={styles.textItem}>
            Присоединяйтесь, делитесь своими рецептами и находите кулинарные идеи от других участников!</p>
          </div>
        </div>
        <aside>
          <h2 className={styles.additionalTitle}>
            Ссылки
          </h2>
          <div className={styles.text}>
            <p className={styles.textItem}>
              Автор проекта: <a href="https://github.com/snegirevdv/" className={styles.textLink}>Denis Snegirev</a>
            </p>
          </div>
        </aside>
      </div>

    </Container>
  </Main>
}

export default About
